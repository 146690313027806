let card_event = [
    {
        image: require("@/assets/images/logo/Eo9.png"),
        text: 'cards.eventCards.descriereEO9',
        modal: {
            logo: require("@/assets/images/logo/Eo9.png"), 
            images: [
                require("@/assets/images/ricardo3.gif"),
                require("@/assets/images/ricardo3.gif"),
                require("@/assets/images/ricardo3.gif"),
            ],
            text: 'cards.eventCards.contentEO9'
        }
    },

    {
        image: require("@/assets/images/logo/decode2.jpg"),
        text: 'cards.eventCards.descriereDeCode',
        modal: {
            logo: require("@/assets/images/logo/decode2.jpg"),
            images: [
                require("@/assets/images/ricardo3.gif"),
                require("@/assets/images/ricardo3.gif"),
                require("@/assets/images/ricardo3.gif"),
            ],
            text: 'cards.eventCards.contentDeCode'
        }
    },

    {
        image: require("@/assets/images/logo/SSA5.png"),
        text: 'cards.eventCards.descriereSSA5',
        modal: {
            logo: require("@/assets/images/logo/SSA5.png"),
             
            text: 'cards.eventCards.contentSSA5'
        }
    },
    {
        image: require("@/assets/images/logo/IDEA.png"),
        text: 'cards.eventCards.descriereIDEA',
        modal: {
            logo: require("@/assets/images/logo/IDEA.png"),
             
            text: 'cards.eventCards.contentIDEA'
        }
    },
    {
        image: require("@/assets/images/logo/Eo8.jpg"),
        text: 'cards.eventCards.descriereEO8',
        modal: {
            logo: require("@/assets/images/logo/Eo8.jpg"),
            text: 'cards.eventCards.contentEO8',
        }
    },
    {
        image: require("@/assets/images/logo/Eo7.png"),
        text: 'cards.eventCards.descriereEO7',
        modal: {
            logo: require("@/assets/images/logo/Eo7.png"),
            text: 'cards.eventCards.contentEO7',
        }
    },
    {
        image: require("@/assets/images/logo/Eo6.png"),
        text: 'cards.eventCards.descriereEO6',
        modal: {
            logo: require("@/assets/images/logo/Eo6.png"),
            text: 'cards.eventCards.contentEO6',
        }
    },
    {
        image: require("@/assets/images/logo/Eo5.png"),
        text: 'cards.eventCards.descriereEO5',
        modal: {
            logo: require("@/assets/images/logo/Eo5.png"),
            text: 'cards.eventCards.contentEO5',
        }
    },
    {
        image: require("@/assets/images/logo/EO4.png"),
        text: 'cards.eventCards.descriereEO4',
        modal: {
            logo: require("@/assets/images/logo/EO4.png"),
            text: 'cards.eventCards.contentEO4',
        }
    },
    {
        image: require("@/assets/images/logo/eo3.jpg"),
        text: 'cards.eventCards.descriereEO3',
        modal: {
            logo: require("@/assets/images/logo/eo3.jpg"),
            text: 'cards.eventCards.contentEO3',
        }
    },
    {
        image: require("@/assets/images/logo/Eo2.jpg"),
        text: 'cards.eventCards.descriereAxwayEO',
        modal: {
            logo: require("@/assets/images/logo/Eo2.jpg"),
            text: 'cards.eventCards.contentAxwayEO',
        }
    },
    {
        image: require("@/assets/images/logo/eestec.png"),
        text: 'cards.eventCards.descriereBalkan',
        modal: {
            logo: require("@/assets/images/logo/eestec.png"),
             
            text: 'cards.eventCards.contentBalkan'
        }
    },
    {
        image: require("@/assets/images/logo/eestec.png"),
        text: 'cards.eventCards.descriereBeLinked',
        modal: {
            logo: require("@/assets/images/logo/eestec.png"),
             
            text: 'cards.eventCards.contentBeLinked'
        }
    },
    {
        image: require("@/assets/images/logo/ride the wire.jpg"),
        text: 'cards.eventCards.descriereCut',
        modal: {
            logo: require("@/assets/images/logo/ride the wire.jpg"),
             
            text: 'cards.eventCards.contentCut'
        }
    },

    {
        image: require("@/assets/images/logo/do it safe.jpg"),
        text: 'cards.eventCards.descriereSafe',
        modal: {
            logo: require("@/assets/images/logo/do it safe.jpg"),
             
            text: 'cards.eventCards.contentSafe'
        }
    },

    {
        image: require("@/assets/images/logo/embedded.png"),
        text: 'cards.eventCards.descriereEmbedded',
        modal: {
            logo: require("@/assets/images/logo/embedded.png"),
             
            text: 'cards.eventCards.contentEmbedded'
        }
    },

    {
        image: require("@/assets/images/logo/EAC.jpg"),
        text: 'cards.eventCards.descriereEnergy',
        modal: {
            logo: require("@/assets/images/logo/EAC.jpg"),
             
            text: 'cards.eventCards.contentEnergy'
        }
    },

    {
        image: require("@/assets/images/logo/euro.jpg"),
        text: 'cards.eventCards.descriereEuro',
        modal: {
            logo: require("@/assets/images/logo/euro.jpg"),
    
            text: 'cards.eventCards.contentEuro'
        }
    },
    {
        image: require("@/assets/images/logo/networking.jpg"),
        text: 'cards.eventCards.descriereNetworKing',
        modal: {
            logo: require("@/assets/images/logo/networking.jpg"),
             
            text: 'cards.eventCards.contentNetworKing'
        }
    },
    {
        image: require("@/assets/images/logo/eestec.png"),
        text: 'cards.eventCards.descriereSmartHome',
        modal: {
            logo: require("@/assets/images/logo/eestec.png"),
             
            text: 'cards.eventCards.contentSmartHome'
        }
    },
    {
        image: require("@/assets/images/logo/t4t.png"),
        text: 'cards.eventCards.descriereT4T',
        modal: {
            logo: require("@/assets/images/logo/t4t.png"),
             
            text: 'cards.eventCards.contentT4T'
        }
    },
    {
        image: require("@/assets/images/logo/eestec.png"),
        text: 'cards.eventCards.descriereHOBOM',
        modal: {
            logo: require("@/assets/images/logo/eestec.png"),
             
            text: 'cards.eventCards.contentHOBOM'
        }
    },
]
export default card_event;