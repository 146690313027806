<template>
  <div id="events">
    <!-- <HeroEvents />  -->
    <h1 class="title">Past events</h1>
    <div class="columns is-multiline is-centered">
      <EventCard class="column is-4" v-for="(event, index) in card_event" :key="index" :event = "event"></EventCard>
    </div>
  </div>
</template>

<script>

import HeroEvents from '@/components/heroes/HeroEvents'
import EventCard from '@/components/cards/EventCard'
import card_event from "@/assets/resources/card-event"

export default {
    components:{
      HeroEvents,
      EventCard,
      card_event
    },
    data () {
      return {
          card_event,
      }
  },
}
</script>

<style lang="scss" scoped>
#events {
  padding-top: 70px;
  padding-bottom: 100px;
}


.title {
  font-size: 60px;
  color: rgb(255, 48, 42);
  margin-bottom: 30px;
  margin-top: 30px;
}

</style>