<template>
    <div id = "event-modal" class="modal-card" style="width: auto">
        <section class="modal-card-body">
            <div class="modal-style">
                <img :src="modalData.logo" class="modal-logo">
                <p class="padding-text" v-html="$t(modalData.text)"></p>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    props: [
        'modalData'
    ]
}
</script>

<style lang="scss">
#event-modal {

    .padding-text{
        padding: 3%;
        margin-top: 10px;
        font-size: 18px;
    }
    width: 100%;
    min-height: 95vh;

    .modal-style {
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
    }
    .modal-logo {
        width: 150px;
        margin-top: 10px;
    }
    .modal-image-row {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 180px;
        margin-bottom: 30px;
    }
    
    .modal-image {
        max-height: 100%;
    }

    .modal-image:not(:last-child) {
        margin-right: 30px;
    }

    .modal-card-body {
        border-radius: 6%;
        overflow: auto;
    }
}

</style>