<template>
  <div id="HeroEvents">
    <div class="container">
      <h1 class="top-left">Upcoming Events...</h1>
      <a href="https://eestec.ro/decode"> <h1 class="bottom-right" >de&#60;code&#62; IT </h1> </a>
    </div> 
    
  </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
#HeroEvents{
  background-color: rgb(254, 149, 149);
}
.container{
  background-image: url("../../assets/images/decode.jpg");
  // background-size:cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 70vh;
}

.top-left {
  position: absolute;
  top: 8px;
  left: 16px;
  color: white;
}

.bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
  color: white;
}

.bottom-right:hover {
  color: darken(#fff, 10%);
}
</style>