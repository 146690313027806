<template>
  <div id="EventCards">
    <div class = "event-card-container">
        <div class = "event-card">
            <img :src ="event.image"  class ="event-card-image" />
            <p>{{$t(event.text)}}</p>
            <button @click="openModal()" class="modal-button is-hidden-mobile"><b-icon icon="chevron-double-down"></b-icon></button>

            <b-modal :active.sync="modalIsOpened" has-modal-card>
                <EventModal :modalData="event.modal"></EventModal>
            </b-modal>
        </div>
    </div>
  </div>
</template>

<script>
import card_event from '@/assets/resources/card-event.js'
import EventModal from './EventModal'

export default { 
    props : [
        'event'
    ],
    components: {
        EventModal
    },
    data() {
        return {
            modalIsOpened: false
        }
    },
    methods: {
        openModal: function () {
            this.modalIsOpened = true;
        } 
    }
}
</script>

<style lang="scss">
#EventCards {

    .event-card-container {
        width: 100%;
        height: 100%;
    }
    .event-card {
        @extend %card-shadow;
        width: 100%;
        min-height: 100%;
        border-radius: 30px;
        background-color: white;
        border: 1px solid grey;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px; 
    }
    .event-card-image {
        height: 140px;
        margin-top: 20px;
    }

    .modal-button {
    width: 50px;
    height: 50px;
    background-color: transparent;
    border: none;
    }

    .modal-button:hover {
        background-color: transparent !important;
    }

    .modal-button:hover span {
        color: grey;
    }

    .animation-content {
        width: 80%;
        height: 95vh;
    }
}
</style>